import React from 'react'

import { Text, Button } from '../components/atoms'
import { Layout, SEO } from '../components/organisms/common'
import * as styles from './contact-success.module.scss'

const ContactSuccess: React.FC = () => {
  return (
    <Layout path="/contact/">
      <div className={styles.container}>
        <Text className={styles.subTitle} as="h2" textType="subTitle">
          CONTACT
        </Text>
        <Text className={styles.thanks} as="p" size="l" textType="engrave">
          お問い合わせありがとうございます
        </Text>
        <p className={styles.description}>ご記入していただいた情報は無事に送信されました。</p>
        <Button className={styles.homeButton} buttonType="link" linkTo="/" shape="round">
          HOME
        </Button>
      </div>
    </Layout>
  )
}

export default ContactSuccess

export const Head = () => <SEO />
